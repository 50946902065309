.cart {
    padding: 1rem;
  }
  
  .cart h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .cart ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cart li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
  }
  