main {
    padding: 2rem;
  }
  
  .App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .homepage-image {
    width: 300px;
    height: auto;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .App-footer {
    text-align: center;
    background-color: #343a40;
    color: white;
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  body {
    background-image: url('/src/components/Images/Tailor_1.JPG');
    background-size: cover; /* Adjust as needed */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: white; /* Adjust text color for better readability */
}

/* Additional styling for other elements */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
}

.header img {
  width: 50px; /* Adjust as needed */
  height: auto;
}

.header nav a {
  color: white;
  margin: 0 1rem;
  text-decoration: none;
}

.header nav a:hover {
  text-decoration: underline;
}

/* Styling for the product list */
.product-list {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.product-card {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  width: 200px; /* Adjust as needed */
}

.product-card img {
  width: 100%;
  border-radius: 10px;
}

.product-card button {
  background-color: green;
  color: rgb(4, 4, 4);
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-card button:hover {
  background-color: darkgreen;
}

/* Example CSS for Responsive Design */

/* Mobile Styles */
body {
  font-size: 14px;
}

.header {
  padding: 10px;
  text-align: center;
}

/* Medium Devices (Tablets) */
@media (min-width: 600px) {
  body {
    font-size: 16px;
  }

  .header {
    padding: 15px;
  }
}

/* Large Devices (Desktops) */
@media (min-width: 992px) {
  body {
    font-size: 18px;
  }

  .header {
    padding: 20px;
  }
}

/* Example CSS using Flexbox */
.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex: 1 1 100%; /* Default to full width on mobile */
}

@media (min-width: 600px) {
  .item {
    flex: 1 1 50%; /* Two columns on tablets */
  }
}

@media (min-width: 992px) {
  .item {
    flex: 1 1 33.33%; /* Three columns on desktops */
  }
}


  