/* General styling for the product list container */
.product-list {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

/* Styling for each product card */
.product-card {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  width: 250px; /* Adjusted size for larger tiles */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

/* Hover effects for product cards */
.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Styling for the product images within the cards */
.product-card img {
  width: 100%;
  border-radius: 10px;
}

/* Styling for the buttons within the cards */
.product-card button {
  background-color: green;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

/* Hover effects for the buttons */
.product-card button:hover {
  background-color: darkgreen;
}

/* Styling for the product titles within the cards */
.product-card .product-title {
  color: #333; /* Darker color for better visibility */
  font-size: 1.2rem;
  margin-top: 0.5rem;
  font-weight: bold;
}

.product-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.product-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  width: 300px;
  text-align: center;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.product-info {
  padding: 20px;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  color: black; 
}

.product-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}

.product-button:hover {
  background-color: #45a049;
}
