.header {
  display: flex;
  align-items: center; /* Ensures vertical alignment is centered */
  justify-content: space-between; /* Spreads content evenly */
  padding: 0.5rem 2rem; /* Adjust padding as needed */
  background-color: #333; /* Adjust background color as needed */
  color: white;
  width: 100%; /* Adjusted width */
  height: 100px; /* Adjusted height */
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds a gap between the logo and the title */
}

.logo {
  width: 50px;  /* Adjust width as needed */
  height: auto;  /* Adjust height as needed */
}

.nav-links {
  display: flex; /* Layout the links in a row */
  gap: 20px; /* Adds space between each link */
}

.nav-link {
  color: white;
  padding: 10px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #f0db4f; /* Changes color on hover */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .logo-container {
    margin-bottom: 10px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%; /* Stacks navigation links vertically on smaller screens */
  }

  .nav-link {
    text-align: center;
    padding: 8px;
    width: 100%; /* Full width links for easier clickability on touch devices */
  }
}
