/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Base Styles */
  body {
    background: none; /* Remove background from body */
    color: white;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    z-index: -1;
  }
  
  .main-content {
    padding: 2rem;
    z-index: 2;
  }
  